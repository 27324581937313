import React from "react"

const SadFace = ({ color, className }) => (
  <svg width={80} height={80} viewBox="0 0 80 80" fill="none" className={className}>
    <path
      d="M64.936 8.756C59.624 4.16 53 2.305 46.153 1.485 27.92-4.331 11.654 7.698 4.552 24.377-3.104 42.352.538 62.42 17.137 73.632c16.946 11.444 40.237 6.74 53.235-8.325 14.207-16.466 10.7-42.585-5.436-56.55zM33.702 74.081c-9.614-1.368-18.941-7.283-23.813-15.717-4.355-7.541-4.318-16.984-2.366-25.22C10.524 20.48 19.605 9.052 31.473 6.317c.017 0 .033.006.05.006 4.27.126 8.782.134 13.19.61.418.131.835.261 1.256.415.695.253 1.303.22 1.812.012 4.757.8 9.3 2.325 13.175 5.374 8.574 6.747 13.094 18.912 12.846 29.567-.466 20.084-21.075 34.486-40.1 31.78z"
      fill={color}
    />
    <path
      d="M28.981 36.28c3.986 0 3.986-6.18 0-6.18-3.985 0-3.985 6.18 0 6.18zM50.306 36.59c3.987 0 3.987-6.181 0-6.181-3.985 0-3.985 6.18 0 6.18zM23.088 51.405c-2.916 2.728 1.464 7.09 4.371 4.37 7.414-6.933 16.967-7.666 24.372-.308 2.828 2.811 7.2-1.558 4.37-4.371-9.818-9.755-23.248-8.916-33.113.31z"
      fill={color}
    />
  </svg>
)

export default SadFace
