import React, {useState} from "react"
import StripeText from "../decoration/stripe-text.js"
import { Link } from "gatsby"

const JoinOffer = ({title,StripeTxt, StripeColor, LinkTo, Linktitle, LinkTxt, offerDescription, place}) => {
    const [isOpen, setIsOppen] = useState(false);

    return (
        <>
            <div className="job-head">
                <div className="titleWrapper" onClick={() => setIsOppen(!isOpen)} >
                    <h3 className="title">{title}</h3>
                    <a className="seemore">En savoir {!isOpen ? 'plus' : 'moins'}</a>
                </div>
                <StripeText color={StripeColor} text={StripeTxt} />
                <span className="location">{place}</span>
                <Link
                    className="link link--black"
                    to={LinkTo}
                    title={Linktitle}
                >
                    {LinkTxt}
                </Link>
                <a onClick={() => setIsOppen(!isOpen)} className="seemoreMobile">En savoir {!isOpen ? 'plus' : 'moins'}</a>
            </div>
            <div className={`description ${isOpen ? 'show' : ''}`} dangerouslySetInnerHTML={{ __html: offerDescription }} />
        </>
    )
}

export default JoinOffer;