import { CONTACT_TAB, JOB_COLORS } from "../../constants.js"

import Layout from "../components/layout/layout"
import { Link } from "gatsby"
import React from "react"
import SEO from "../components/seo.js"
import SadFace from "../components/decoration/sad-face.js"
import TitleHeader from "../components/title-header/title-header.js"
import JoinOffer from "../components/join-offer/join-offer.js";
import { graphql } from "gatsby"


const getTypes = (types) => {
  let txt = '';
  types.map((elem, i) => {
    if(i === 0) txt = elem.name.toUpperCase();
    else txt = txt + ', ' + elem.name.toUpperCase();
  })

  return txt;
}

const Join = ({ location, pageContext, data: { offers, content } }) => {
  const hasJobs =
    offers && offers.nodes[0] && offers.nodes[0].contracts
      ? offers.nodes[0].contracts.nodes
      : false
  const pageContent =
    content.nodes && content.nodes[0] ? content.nodes[0].join_content : false
  const seo = pageContext.page.seo

  return (
    <Layout location={location}>
      <SEO meta={seo} />
      <div className={"container"}>
        <div className={"page-join"}>
          {pageContent && (
            <>
              <TitleHeader
                title={pageContent.title}
                paragraph={pageContent.subtitle}
              />
              <h2>{pageContent.jobsTitle}</h2>
            </>
          )}

          {hasJobs ? (
            <ul className="jobs">
              {offers.nodes.map(({ contracts, job_content, title }, i) => {
                // const type =
                //   contracts.nodes && contracts.nodes[0].name
                //     ? contracts.nodes[0].name.toUpperCase()
                //     : "DEFAULT"

                const stripeColor = contracts.nodes && contracts.nodes[0].name
                    ? contracts.nodes[0].name.toUpperCase()
                    : "DEFAULT"

                const type = getTypes(contracts.nodes);

                const place =
                  job_content && job_content.place ? job_content.place : "Paris"
                return (
                  <li className="job" key={i}>
                    <JoinOffer 
                      title={title}
                      StripeTxt={type}
                      StripeColor={JOB_COLORS[stripeColor]}
                      LinkTo={`${pageContent.spontaneousApplication.buttonLink.uri}${CONTACT_TAB.JOIN}`}
                      Linktitle={pageContent.spontaneousApplication.buttonTitle}
                      LinkTxt={pageContent.spontaneousApplication.buttonTitle}
                      offerDescription={job_content.description}
                      place={place}
                    />
                  </li>
                )
              })}
            </ul>
          ) : (
            <div className="no-jobs">
              <h3>{pageContent.noApplication.title}</h3>
              <p>{pageContent.noApplication.texte}</p>
              <Link
                className="link link--black"
                to={`${pageContent.noApplication.buttonLink.uri}${CONTACT_TAB.JOIN}`}
                title={pageContent.noApplication.buttonTitle}
              >
                {pageContent.noApplication.buttonTitle}
              </Link>
              <SadFace className={"left"} color="#C3BFBF" />
              <SadFace className={"right"} color="#C3BFBF" />
            </div>
          )}

          {hasJobs && (
            <div className="job-apply">
              <p>{pageContent.spontaneousApplication.texte}</p>
              <Link
                className="link link--black"
                to={`${pageContent.spontaneousApplication.buttonLink.uri}${CONTACT_TAB.JOIN}`}
                title={pageContent.spontaneousApplication.buttonTitle}
              >
                {pageContent.spontaneousApplication.buttonTitle}
              </Link>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Join

export const query = graphql`
  query JoinQuery {
    offers: allWpJob {
      nodes {
        contracts {
          nodes {
            name
          }
        }
        job_content {
          place
          description
        }
        title
      }
    }
    content: allWpPage(filter: { slug: { eq: "join" } }) {
      nodes {
        slug
        title
        join_content {
          title
          subtitle
          jobsTitle
          noApplication {
            buttonLink {
              ... on WpPage {
                uri
              }
            }
            buttonTitle
            texte
            title
          }
          spontaneousApplication {
            buttonLink {
              ... on WpPage {
                uri
              }
            }
            buttonTitle
            texte
          }
        }
      }
    }
  }
`
